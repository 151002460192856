import moment from 'moment';
import {storage} from "./storage";

export const commonData = {
    addToHomeButtonInstallPrompt: null
}

export const commonVariables = {
    lastScrollDirection: '',
    fullWindowHeight: window.innerHeight
}

export function showElipses(str, n) {
    return (str.length > n) ? str.substr(0, n - 1) + '&hellip;' : str;
}

export function createFormData(data) {
    let formData = new FormData();
    for (let key in data) {
        formData.append(key, data[key]);
    }
    return formData;
}


export function createURLSearchParams(data) {
    let formData = new URLSearchParams();
    for (let key in data) {
        formData.append(key, data[key]);
    }
    return formData;
}

export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function showToast({ msg, setToastState }) {
    setToastState({
        show: true, msg
    })
    setTimeout(() => {
        setToastState({
            show: false, msg: null
        })
    }, 3000)

}

export function isCardValid(number) {
    var regex = new RegExp("^[0-9]{16}$");
    if (!regex.test(number))
        return false;

    return luhnCheck(number);
}

function luhnCheck(val) {
    var sum = 0;
    for (var i = 0; i < val.length; i++) {
        var intVal = parseInt(val.substr(i, 1));
        if (i % 2 === 0) {
            intVal *= 2;
            if (intVal > 9) {
                intVal = 1 + (intVal % 10);
            }
        }
        sum += intVal;
    }
    return (sum % 10) === 0;
}

export function generateClientId() {
    // debugger;
    return `sup_gbw_${new Date().getTime()}${Math.random().toString(36).substr(2, 5).toUpperCase()}`;
}

export function copyObject(obj) {
    return JSON.parse(JSON.stringify(obj))
}

export function consoleLog(method, data) {
    console.log(method, data)
}

export function setLocal(key, value, isJson = false) {
    return storage.setItem(key, value, isJson ? "json" : undefined);
}

export function getLocal(key, isJson = false) {
    let value = localStorage.getItem(key)
    return value ? (isJson ? JSON.parse(value) : value) : null
}
export function removeLocal(key) {
    return storage.removeItem(key);
}
export function clearLocal() {
    localStorage.clear()
}

export function getDateString(d, format = "yyyy-mm-dd") {
    if (!d) d = new Date();
    format = format.replace("dd", preZero(d.getDate()));
    format = format.replace("mm", preZero(d.getMonth() + 1));
    format = format.replace("yyyy", d.getFullYear());
    return format;
}
function preZero(n) {
    if (n > 9) {
        return n;
    }
    return "0" + n;
}

export function getCityFromAddressComponent(addressComponent) {
    let address = (addressComponent || []).find(it => it.types.indexOf('locality') > -1 || it.types.indexOf('administrative_area_level_2') > -1);
    return address ? (address.long_name || address.short_name) : '';
}


export function checkAutoLogin() {

    try {
        window.loginKey = getParameterByName('key')
    } catch (e) {
    }
}

export function isPlatformWebView() {

    try {
        return navigator && navigator.userAgent && navigator.userAgent.includes('wv')
    } catch (e) {

    }
}

export function printContent(content) {
    var a = window.open('', '', 'height=500, width=900');
    a.document.write('<html>');
    a.document.write('<body> <div style="white-space:pre-line;">');
    a.document.write(content);
    a.document.write('</div></body></html>');
    a.document.close();
    a.print();
}


export function getFormattedDate (dateString, dateFormat) {
    return moment(dateString).format(dateFormat)
}

export function isValidPhoneNumber (phoneNumber) {
    // should start from number between 6-9 and should be of 10 digit
    let validPattern = new RegExp('^[6-9]\\d{9}$');
    return validPattern.test(phoneNumber)
}

export function isValidNoShipments (phoneNumber) {
    // should start from number between 6-9 and should be of 10 digit
    let validPattern = new RegExp('^[1-9]\\d*$');
    return validPattern.test(phoneNumber)
}

export function validateAddress (data) {
    // if void apartment is selected then the minimum length of the address string should be 10 characters
    let voidApartments = ['', '-1', '0']
    if (voidApartments.includes(data.apt_id)) {
        if ((data.address).length < 10) {
            return false
        }
    }  else {
        if(data.apt_id && !(voidApartments.includes(data.apt_id))){
            if ((data.address).length < 1) {
                return false
            }
        } else {
            if((data.address).length < 10){
                return false
        }
    }
    }
    return true
}

export function isBeforeToday(dateString) {
    return moment(dateString).isBefore(moment().format("YYYY-MM-DD"))
}

export function isSameDate(dateString) {
    let formattedDate = moment(dateString).format("YYYY-MM-DD")
    return moment(formattedDate).isSame(moment().format("YYYY-MM-DD"))
}

export function getTimeDiff(dateString1, dateString2, unit='minutes') {
    let diff = moment(dateString1).diff(moment(dateString2), unit)
    return diff
}

export function isCurrentOptionPresentInBusinessOptions(option, businessOptions) {
    const optionsArray = (businessOptions || "").split(',');
    return optionsArray.includes(option)
}

const SCHEDULED_HOURS =[[4, 6], [6, 8], [8, 10], [10, 12], [12, 14], [14, 16], [16, 18], [18, 20], [20, 22]]

const getUpcomingTimeSchedules = () => {
    const currentHour = moment().hour();
    let upComingSchedules = SCHEDULED_HOURS.filter((slot) => slot[0] >= currentHour + 2);
    return upComingSchedules;
}

export const getAvailableSlots = () => {
    const slotHours = getUpcomingTimeSchedules();
    let availableSlots = slotHours.map((slot) => {
        const slotStartTime = moment().set({'hour': slot[0], 'minute': 0, 'second': 0});
        const slotEndTime = moment().set({'hour': slot[1], 'minute': 0, 'second': 0});
        return {
            slot: moment(slotEndTime).format('YYYY-MM-DD HH:mm:ss'),
            label: `Ready after ${moment(slotStartTime).format('hh:mmA')}`
        }
    })
    availableSlots.unshift({slot: null, label: 'Ready now'})
    return availableSlots;
}

export const getStandardEtaSlot = () => {
    const slotTime = moment().add(2, 'hours').format('YYYY-MM-DD HH:mm:ss');
    return slotTime;
}
