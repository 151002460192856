
import React, {useEffect} from "react";
import { Redirect, Route } from "react-router-dom";
import {useRecoilValue, useSetRecoilState} from "recoil";

import { authState } from "../common/recoil/atoms";

const AuthRoute = ({ component: Component, ...rest }) => {
  let { clientid, username, authtoken, deliveryByGoodbox, redirectPath } = useRecoilValue(authState);
  const setAuthDta = useSetRecoilState(authState);
  if (!clientid || !username || !authtoken) {
    clientid = window.localStorage.getItem('clientid')
    username = window.localStorage.getItem('username')
    authtoken = window.localStorage.getItem('authtoken')
  }

  console.log('auth route triggered', clientid, username, authtoken, +deliveryByGoodbox);

  const newRedirectPath = rest.location.pathname + rest.location.search;

  useEffect(() => {
    if (newRedirectPath && !redirectPath && newRedirectPath !== '/confirm-account') {
      const lsRedirectPath = localStorage.getItem('redirectTo');
      if (!lsRedirectPath) {
        localStorage.setItem('redirectTo', newRedirectPath);
      }
      setAuthDta((oldState) => {
        return {
          ...oldState,
          redirectPath: newRedirectPath
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const urlRedirPath = redirectPath ? `?redirectTo=${redirectPath}` : newRedirectPath ? `?redirectTo=${newRedirectPath}` : '';

  // return <></>

  return (
    <Route
      {...rest}
      render={(props) =>
        (clientid && username && authtoken) ? (
          (deliveryByGoodbox && +deliveryByGoodbox === 1) || (rest.path && rest.path.indexOf("confirm-account") > -1) || (rest.path && rest.path.indexOf("create-account") > -1  && !window.loginKey)  ? <Component {...props} />
            : <Redirect to={`/confirm-account${urlRedirPath}`} />
        ) : (
            <Redirect to={`/login${urlRedirPath}`} />
          )
      }
    />
  );
};

export default AuthRoute;
