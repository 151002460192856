export const GTagEvents = {
    BTN_ENABLE_NOTIFICATION: 'BTN_ENABLE_NOTIFICATION',
    BTN_ADD_TO_HOME: 'BTN_ADD_TO_HOME',
    LOGIN_BTN_CLICKED: 'LOGIN_BTN_CLICKED',
    LOGIN_SUCCESS: 'login',
    ADD_TO_CART: 'add_to_cart',
    ORDER_SUCCESS: 'purchase'
}

export function googleTagEvent(eventName, data) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'event':eventName, ...data});
    
    window.AggregatedataLayer = window.AggregatedataLayer || [];
    window.AggregatedataLayer.push({'event':eventName, ...data});
}