import axios from "axios";
import { config } from "./config";
import * as Sentry from "@sentry/react";

export const GDBXAPI = (method, url, payload) => { return API(method, url, payload, config.gdbxApiHostname, getGdbxApiHeaders("application/json")); }
export const RETAILAPI = (method, url, payload) => { return API(method, url, payload, config.retailApiHostname, getGdbxApiHeaders("application/json")); }
export const RETAILFORMAPI = (method, url, payload) => { return FORMAPI(method, url, payload, config.retailApiHostname, getGdbxApiHeaders("multipart/form-data")); }
export const CATALOGAPI = (method, url, payload) => { return API(method, url, payload, config.catalogApiHostname, getGdbxApiHeaders("application/json")); }
export const GDBXFORMAPI = (method, url, payload) => { return FORMAPI(method, url, payload, config.gdbxApiHostname, getGdbxApiHeaders("multipart/form-data")); }
export const CALLTHIRDPARTYAPI = (method, url, payload, contentType) => { return THIIRDPARTYAPI(method, url, payload, contentType); }

var userAgent = window.navigator.userAgent.toLowerCase(),
    ios = /iphone|ipod|ipad/.test( userAgent );

const getGdbxApiHeaders = function(contentType) {
   return {
    "Content-Type": contentType,
    "X-GDBX-CLIENTID": window.localStorage.getItem("clientid") || "",
    "X-GDBX-AUTHTOKEN": window.localStorage.getItem("authtoken") || "",
    "X-GDBX-USERNAME": window.localStorage.getItem("username") || "",
    "X-GDBX-VER": config.version,
    "X-GDBX-PACKAGE": window.ReactNativeWebView ? "com.tsepak.supplierchat" : config.package,
    "X-GDBX-CLIENT": window.ReactNativeWebView ? (ios ? "ios" : "android") : "webapp"
   };
 };

const API = (method, url, payload, server, headers) => {
  let options = {
    headers: headers,
    method,
    url: `${server}${url}`,
  };

  if (method === "POST" || method === "PATCH") {
    options.data = payload;
  }
  //
  // if (window.loginKey && !window.__loginKeyLoginDone) {
  // } else {
  //   if (!window.___gdbxClientId) {
  //     window.___gdbxClientId = headers['X-GDBX-CLIENTID'];
  //   } else {
  //     const lsClientId = window.localStorage.getItem('clientid');
  //     if (lsClientId !== window.___gdbxClientId && !window.secondLoginDetected) {
  //       window.secondLoginDetected = true;
  //       alert("Second login detected. Reloading...");
  //       window.location.reload();
  //     }
  //   }
  // }


  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        return res.data;
      })
      .then((respJson) => {
        resolve(respJson);
      })
      .catch((err) => {
        Sentry.captureException(err, url)
        reject(err);
      });
  });
};

const FORMAPI = (method, url, payload, server, headers) => {
  let options = {
    headers: headers,
    method,
    url: `${server}${url}`,
  };

  if (method === "POST" || method === "PATCH") {
    let formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key]);
    }
    options.data = formData;
  }

  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        return res.data;
      })
      .then((respJson) => {
        resolve(respJson);
      })
      .catch((err) => {
        Sentry.captureException(err, url)
        reject(err);
      });
  });
};

const THIIRDPARTYAPI = (method, url, payload, contentType) => {
  let options = {
    headers: {
      "Content-Type": contentType || "application/json",
    },
    method,
    url: `${url}`,
  };

  if (method === "POST" || method === "PATCH") {
    options.data = payload;
  }
  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        return res.data;
      })
      .then((respJson) => {
        resolve(respJson);
      })
      .catch((err) => {
        Sentry.captureException(err, url)
        reject(err);
      });
  });
};
