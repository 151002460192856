import {messaging} from "../push/init-fcm";

const getItem = (key) => {
    return new Promise((resolve, reject) => {
        if (window.ReactNativeWebView) {
            window.webViewBridge.send("getValue", {key: key}, (res) => {
                console.log('got value from webview: ', res);
                resolve(res);
            }, err => {
                reject(err);
            });
        } else {
            resolve(window.localStorage.getItem(key));
        }
    });
}

const setItem = (key, val, typeHint) => {
    console.log("setItem ", key, typeof val, val);
    let isJson = false;
    let _v = val;
    if (typeof _v === "number") {
        _v = _v.toString();
    }
    if (typeof _v === "object") {
        isJson = true;
        _v = JSON.stringify(_v);
    }
    window.localStorage.setItem(key, _v);

    if (isJson) {
        _v = btoa(_v);
    }

    return new Promise((resolve, reject) => {
        if (window.ReactNativeWebView) {
            window.webViewBridge.send("saveValue", {key: key, value: _v}, (res) => {
                resolve();
            }, err => {
                reject(err);
            });
        } else {
            resolve();
        }
    });
}

const removeItem = (key) => {
    window.localStorage.removeItem(key);
    return new Promise((resolve, reject) => {
        if (window.ReactNativeWebView) {
            window.webViewBridge.send("removeValue", {key: key}, (res) => {
                resolve();
            }, err => {
                reject(err);
            });
        } else {
            resolve();
        }
    });
}

const hydrateItem = async function (key, typeHint) {
    console.log('hydrateItem ', key);
    // Check localStorage first and fallback to fetching from native
    const localValue = window.localStorage.getItem(key);
    if (localValue && localValue.length > 0) {
        return localValue;
    }
    let val = await getItem(key);
    if (val && !window.ReactNativeWebView) {
        return;
    }
    if (val && typeHint === "json") {
        val = atob(val);
    }
    if (!!val) {
        window.localStorage.setItem(key, val);
    }
    return val;
}

const getPushToken = function () {
    return new Promise(async (resolve, reject) => {
        try {
            if (window.ReactNativeWebView) {
                window.webViewBridge.send("getPushToken", {}, (res) => {
                    console.log('got push token from webview: ', res);
                    resolve(res);
                }, err => {
                    reject(err);
                });
            } else {
                if (messaging) {
                    const token = await messaging.getToken();
                    if (token) {
                        storage.setItem('pushToken', token)
                    }
                    resolve(token);
                }
            }
        } catch (err) {
            console.error(err);
            reject(err);
        }
    });
}

export const storage = {
    getItem,
    setItem,
    hydrateItem,
    removeItem,
    getPushToken
}
