import * as Sentry from "@sentry/react";

import { CALLTHIRDPARTYAPI, GDBXAPI, GDBXFORMAPI, CATALOGAPI, RETAILAPI, RETAILFORMAPI } from "../api";
import {
  loginState, loginLoadingState, toastState, authState, loaderState, ordersState, apartmentListState, customerProfileState,
  ridersState, rosterRidersState, teamMembersState, issuesState, pickerOrdersState, dashboardNotesState,
  chatState,
  ridersDashboardState,
  distanceInfoState,
  storeProfileInfostate,
  businessMultiStoreState,
  areaNameState
} from "./atoms";
import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { config } from "../config";
import { consoleLog, generateClientId, removeLocal, setLocal, clearLocal, getCityFromAddressComponent } from "../services/CommonService";
import { showToast } from "../services/CommonService";
import { googleTagEvent, GTagEvents } from "../services/GoogleTagService";
import { useHistory } from "react-router-dom";
import _ from 'underscore'
import moment from 'moment';
import { storage } from "../services/storage";
import {messaging} from "../push/init-fcm";


export function useInterval(callback, delay) {
  const intervalRef = React.useRef();
  const callbackRef = React.useRef(callback);

  // Remember the latest callback:
  //
  // Without this, if you change the callback, when setInterval ticks again, it
  // will still call your old callback.
  //
  // If you add `callback` to useEffect's deps, it will work fine but the
  // interval will be reset.

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the interval:

  React.useEffect(() => {
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(() => callbackRef.current(), delay);

      // Clear interval if the components is unmounted or the delay changes:
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);

  // Returns a ref to the interval ID in case you want to clear it manually:
  return intervalRef;
}



export function RestoreAuthFromStorage() {
  const setAuth = useSetRecoilState(authState);
  const authtoken = window.localStorage.getItem("authtoken");
  const username = window.localStorage.getItem("username");
  const address = window.localStorage.getItem("address");
  const phone = window.localStorage.getItem("phone");
  const businessId = window.localStorage.getItem("businessId");
  let clientid = window.localStorage.getItem("clientid");
  const getBusinessDetails = useGetBusinessDetails();
  const history = useHistory();
  const setAuthDta = useSetRecoilState(authState);
  const setLoaderState = useSetRecoilState(loaderState);
  console.log('restore auth from storage client id', clientid);
  if (!clientid) {
    clientid = generateClientId();
    try {
      // mherle(20dec16): ideally, this line should ever be executed only once.
      storage.setItem("clientid", clientid);
    } catch (Exception) {
      // mherle(20dec16): window.localStorage.setItem will throw exception when you're working in private mode of safari.
    }
  }

  const loginKeyLoginDone = React.useRef(false);

  useEffect(() => {
    // window.parseUrl = function (n, o) { var t = null, i = []; return (o = o.split("?")[1]) && (o = o.split("&")) ? (o.forEach(function (o) { (i = o.split("="))[0] === n && (t = decodeURIComponent(i[1])) }), t) : t };
    // var encrypted = window.parseUrl('key', window.location.href);

    window._fcmTokenListener && window._fcmTokenListener.addEventListener('sentToken', (e) => {
      console.log("sent token fired: ", e);
      setAuth((oldState) => {
        return {
          ...oldState,
          fcmTokenSent: true,
          inWebView: !!window.ReactNativeWebView
        };
      });
    });

    if (window.loginKey && !loginKeyLoginDone.current) {
      loginKeyLoginDone.current = true;
      // Previous login detected
      if (authtoken) {
        alert("Previous login detected. Please logout first.");
        window.location = "/dashboard/home";
        return;
      }
      var decrypted = JSON.parse(atob(decodeURIComponent(window.loginKey)));
      if (decrypted.u && decrypted.p) {
        messaging && messaging.deleteToken().then(r => console.log(r)).catch(err => console.log(err));
        setLoaderState(true);
        const payload = { "parse_username": decrypted.u, "parse_password": decrypted.p }
        RETAILAPI(
          "POST",
          `/partner-api/general/verify-login`,
          JSON.stringify(payload)
        )
          .then((res) => {
            setLocal('businessId', res.businessId || (res.business && res.business.supplierLoggedInId)).then(() => {
              setLoaderState(false);
              setLogin(res, history, setAuthDta, res, getBusinessDetails, true);
            });
          })
          .catch((err) => {
            setLoaderState(false);
            console.log(err);
          });
      }
    } else {
      if (clientid && authtoken && username) {
        console.log('restored auth');
        storage.getPushToken().then(token => {
          console.log('sending push token ', clientid);
          window._sendFcmToken({
            "clientId": clientid,
            "pushToken": token
          })
        }).catch(err => {

        });

        getBusinessDetails(true);

        setAuth((oldState) => {
          Sentry.configureScope(function (scope) {
            scope.setUser({ "id": username, "clientId": clientid });
          });
          return {
            ...oldState,
            clientid,
            authtoken,
            username,
            phone,
            businessId,
            address: address ? JSON.parse(address) : undefined,
            authRestored: true
          };
        });
        // setTimeout(() => getBusinessDetails(),1000)
      }
    }
    // eslint-disable-next-line
  }, [setAuth, authtoken, clientid, username, address, phone, businessId,]);

  return null;
}




export function useLogout() {
  const history = useHistory();
  const setAuthDta = useSetRecoilState(authState);

  return () => {
    RETAILAPI(
        "POST",
        `/partner-api/general/logout`
    )
        .then((res) => {

        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setAuthDta({});
          messaging && messaging.deleteToken().then(r => console.log(r)).catch(err => console.log(err));
          clearLocal();
          history.replace('', null); // remove navigation history
          window.location.pathname = '/login'
        });
  };
}


export function useUpdatePhone() {
  const setLoginState = useSetRecoilState(loginState);

  return (phone) => {
    setLoginState((oldState) => {
      return {
        ...oldState,
        phone: (phone + "").replace(/[^0-9]/g, ""),
      };
    });
  };
}

export function useUpdateOtp() {
  const setLoginState = useSetRecoilState(loginState);

  return (otp) => {
    setLoginState((oldState) => {
      return {
        ...oldState,
        otp,
      };
    });
  };
}

export function useSendOtp() {
  const [userData, setUserData] = useRecoilState(loginState);
  const setLoginLoadingState = useSetRecoilState(loginLoadingState);
  return (otpMethod, onLoad) => {
    setLoginLoadingState(true)
    consoleLog('useSendOtp', { mobile: userData["phone"] })
    googleTagEvent(GTagEvents.LOGIN_BTN_CLICKED)
    RETAILAPI(
      "POST",
      `/partner-api/customer/send-otp`,
      { mobile: userData["phone"], otpMethod }
    )
      .then((res) => {
        setLoginLoadingState(false);
        setUserData({
          username: res.username || userData["phone"],
          phone: userData["phone"],
        });
        if (onLoad) onLoad(res)
      })
      .catch((err) => {
        setLoginLoadingState(false);
        console.log(err);
      });
  };
}

export function useVerifyOtp() {
  const history = useHistory();
  const userData = useRecoilValue(loginState);
  const setAuthDta = useSetRecoilState(authState);
  const setToastState = useSetRecoilState(toastState);
  const setLoginLoadingState = useSetRecoilState(loginLoadingState);

  return (onLoad) => {
    setLoginLoadingState(true)
    consoleLog('useVerifyOtp', { mobile: userData["phone"], otp: userData["otp"] })
    RETAILAPI(
      "POST",
      `/partner-api/customer/verify-otp`,
      { mobile: userData["phone"], otp: userData["otp"] }
    )
      .then((res) => {
        setLoginLoadingState(false);
        if (res.status !== -1) {
          setLogin(res, history, setAuthDta, userData);
        } else {
          showToast({ msg: 'OTP entered is incorrect. Please recheck or request call.', setToastState })
        }
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoginLoadingState(false);
        console.log(err);
      });
  };
}


function setLogin(res, history, setAuthDta, userData, getBusinessDetails, loginKeyLoginDone) {
  googleTagEvent(GTagEvents.LOGIN_SUCCESS,
    {
      'authenticationMethod': 'Phone',
      'userId': res.username
    }
  )

  storage.setItem("authtoken", "light");
  storage.setItem("authtoken", res.authtoken);
  storage.setItem("clientid", res.clientid);
  storage.setItem("username", res.username);
  storage.setItem("phone", userData ? userData["phone"] : '');


  removeLocal('signupBusinessDetails');
  removeLocal('signupBusinessLocationDetails');
  removeLocal('signupBusinessWhatsappDetails');
  removeLocal('signupBusinessPictureDetails');

  setAuthDta((oldState) => {
    return {
      // ...oldState,
      authtoken: res.authtoken,
      clientid: res.clientid,
      username: res.username,
      business: res.business,
      phone: userData && userData["phone"],
      businessId: res.businessId || (res.business && res.business.supplierLoggedInId),
      deliveryByGoodbox: res.business && res.business.deliveryByGoodbox,
      authRestored: true
    };
  });

  if (loginKeyLoginDone) {
    window.__loginKeyLoginDone = true;
  }

  if (res.business) {
    setLocal('businessCreated', 'true');
    setLocal('businessId', res.business.supplierLoggedInId)
    history.replace('', null); // remove navigation history
    if (res.business.deliveryByGoodbox === 1) {
      const redirectTo = window.localStorage.getItem('redirectTo');
      if (loginKeyLoginDone && redirectTo) {
        window.localStorage.removeItem('redirectTo');
        history.push(redirectTo);
      } else {
        history.push("/dashboard/home");
      }
      storage.getPushToken().then(token => {
        window._sendFcmToken({
          "clientId": res.clientid,
          "pushToken": token
        })
      }).catch(err => {

      })
    } else {
      history.push("/confirm-account");
    }
  } else {
    if (getBusinessDetails) {
      getBusinessDetails(true)
    } else {
      removeLocal('businessCreated');
      removeLocal('business');
      history.replace('', null); // remove navigation history
      history.push("/create-account");
    }
  }
}




export function useSendWhatsappOtp() {
  const setLoaderState = useSetRecoilState(loaderState);
  return (mobile, otpMethod, onLoad) => {
    setLoaderState(true)
    consoleLog('useSendWhatsappOtp', { mobile: mobile, otpMethod })
    RETAILAPI(
      "POST",
      `/partner-api/whatsapp/send-otp`,
      { mobile: mobile, otpMethod }
    )
      .then((res) => {
        setLoaderState(false);
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
      });
  };
}


export function useVerifyWhatsappOtp() {
  const setLoaderState = useSetRecoilState(loaderState);
  return (mobile, otp, onLoad) => {
    setLoaderState(true)
    consoleLog('useVerifyWhatsappOtp', { mobile: mobile, otp })
    RETAILAPI(
      "POST",
      `/partner-api/whatsapp/verify-otp`,
      { mobile: mobile, otp }
    )
      .then((res) => {
        setLoaderState(false);
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
      });
  };
}



export function useUploadPhoto() {
  const setLoaderState = useSetRecoilState(loaderState);
  return (file, onLoad) => {
    setLoaderState(true)
    consoleLog('upload', { file })
    RETAILFORMAPI(
      "POST",
      `/partner-api/general/photo/upload`,
      { file }
    )
      .then((res) => {
        setLoaderState(false);
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
      });
  };
}



export function useGetGoogleAddress() {

  return (lat, lng, onLoad) => {
    CALLTHIRDPARTYAPI(
      "GET",
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=true&key=${config.googleGeoCodingKey}`
    )
      .then((res) => {
        if (res && res.results && res.results.length > 0) {
          if (onLoad) {
            onLoad({
              location: { lat, lng },
              address: res.results[0].formatted_address,
              photo: (res.results[0].photos || []).length > 0 ? res.results[0].photos[0].getUrl() : '',
              placeId: res.results[0].place_id,
              city: getCityFromAddressComponent(res.results[0].address_components)
            })
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}



export function useUseCreateBusiness() {
  const history = useHistory();
  const setLoaderState = useSetRecoilState(loaderState);
  return (data) => {
    setLoaderState(true)
    consoleLog('useUseCreateBusiness', data)
    RETAILAPI(
      "POST",
      `/partner-api/business/create`,
      data
    )
      .then((res) => {
        setLoaderState(false);
        history.replace('', null); // remove navigation history
        setLocal('businessCreated', 'true');
        history.push("/confirm-account");
        // window.localStorage.setItem("accountCreated", "true");

      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
      });
  };
}





export function useFetchOrders() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  const setOrderState = useSetRecoilState(ordersState);
  const orders = useRecoilValue(ordersState);
  return (date, onLoad) => {
    setLoaderState(true)
    consoleLog('useFetchOrders', { "business_id": userAuth.businessId, "created_at": date, "order_mode": ["home-delivery"] })
    RETAILAPI(
      "POST",
      `/partner-api/orders/fetch`,
      { "business_id": userAuth.businessId, "created_at": date, "order_mode": ["home-delivery"] }
    )
      .then((res) => {
        setLoaderState(false);
        const allOrders = res.data;
        const newOrders = _.where(res.data, { delivery_status: 'created' });
        const billedOrders = _.where(res.data, { delivery_status: 'billed' });
        const pickedOrders = _.where(res.data, { delivery_status: 'picked' });
        const deliveredOrders = _.where(res.data, { delivery_status: 'delivered' });
        const newCount = _.where(res.data, { delivery_status: 'created' }).length;
        const billedCount = _.where(res.data, { delivery_status: 'billed' }).length;
        const pickedCount = _.where(res.data, { delivery_status: 'picked' }).length;
        const deliveredCount = _.where(res.data, { delivery_status: 'delivered' }).length;

        setOrderState({ ...orders, allOrders, newOrders, billedOrders, pickedOrders, deliveredOrders, newCount, billedCount, pickedCount, deliveredCount })
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function useCreateOrder() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  return (phone, order, businessId='', useLastAddress, selectedAddress, onLoad) => {
    if(!businessId){
      businessId = userAuth.businessId;
    }
    consoleLog('useCreateOrder');
    setLoaderState(true)
    RETAILAPI(
        "POST",
        `/partner-api/orders/create`,
        JSON.stringify({
          phone: phone,
          src: "store-whatsapp",
          business_id: businessId,
          call_for_delivery: false,
          comments: order,
          ...!useLastAddress && { address: selectedAddress },
        })
    )
        .then(res => {
          setLoaderState(false)
          if (onLoad) onLoad(res);
        }).catch(err => {
          setLoaderState(false)
          if (onLoad) onLoad(err.toJSON())
    })
  }
}


export function useFetchRiders() {
  const userAuth = useRecoilValue(authState);
  const setRidersState = useSetRecoilState(ridersState);
  return (onLoad) => {
    consoleLog('useFetchRiders', { "business_id": userAuth.businessId })
    RETAILAPI(
      "POST",
      `/partner-api/general/riders`,
      { "businessId": userAuth.businessId }
    )
      .then((res) => {
        if (res.status === 0 && res.data) {
          let riders = res.data.riders;
          riders = riders.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
          setRidersState(riders)
        }
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function useFetchareaName() {
  const userAuth = useRecoilValue(authState);
  const setareaNameState = useSetRecoilState(areaNameState);
  return (onLoad) => {
    consoleLog('useFetchareaName', { "business_id": userAuth.businessId })
    RETAILAPI(
      "POST",
      `/partner-api/general/area_names`,
      { "businessId": userAuth.businessId }
    )
      .then((res) => {
        if (res.status === 0 && res.data) {
          let area_names = res.data.area_names;
          area_names = area_names.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
          setareaNameState(area_names)
        }
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}



export function useFetchRosterRiders() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  const setRosterRidersState = useSetRecoilState(rosterRidersState);
  const setRidersDasboardState = useSetRecoilState(ridersDashboardState);
  return (rosterDate, onLoad) => {
    setLoaderState(true)
    consoleLog('useFetchRosterRiders', { "business_id": userAuth.businessId, rosterDate })
    GDBXAPI(
      "POST",
      `/goodbox/partnerapi/roster/riders`,
      { "businessId": userAuth.businessId, rosterDate }
    )
      .then((res) => {
        setLoaderState(false)
        if (res.status === 0 && res.data) {
          setRosterRidersState(res.data.roster)
          setRidersDasboardState(res.data.ridersView)
        }
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false)
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}


export function useCancelOrder() {
  const setLoaderState = useSetRecoilState(loaderState);
  return (trackingId, reason, initateReturn, onLoad) => {
    setLoaderState(true)
    RETAILAPI(
      'POST',
      '/partner-api/orders/cancel',
      { trackingId, source: reason || "Canceled by Supplier", returnInitated: initateReturn }
    )
      .then((res) => {
        setLoaderState(false);
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function useChangeVendor() {
  const setLoaderState = useSetRecoilState(loaderState);
  return (trackingId, vendor, onLoad) => {
    setLoaderState(true)
    RETAILAPI(
      'POST',
      '/partner-api/orders/change-vendor',
      { trackingId, deliveryVendor: vendor || "GBDEL" }
    )
      .then((res) => {
        setLoaderState(false);
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function useUpdateOrderStatus() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  return (trackingId, status, onLoad) => {
    setLoaderState(true)
    RETAILAPI(
      'POST',
      '/partner-api/orders/update-status',
      { trackingId, status, "businessId": userAuth.businessId}
    )
      .then((res) => {
        setLoaderState(false);
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  }
}

export function useAssignRider() {
  const setLoaderState = useSetRecoilState(loaderState);
  return (deliveryId, driverId, onLoad) => {
    setLoaderState(true)
    RETAILAPI(
      'POST',
      '/partner-api/orders/change-rider',
      { driverId, deliveryId }
    )
      .then((res) => {
        setLoaderState(false);
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}


export function useGenerateOtp() {
  const userAuth = useRecoilValue(authState);
  const setLoaderState = useSetRecoilState(loaderState);
  return (trackingId, onLoad) => {
    const data = {
      trackingId,
      "businessId": userAuth.businessId
    }
    setLoaderState(true)
    consoleLog('useGenerateOtp', data)
    GDBXAPI(
      "POST",
      `/goodbox/partnerapi/order/generateotp`,
      data
    )
      .then((res) => {
        setLoaderState(false);
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function CreateOrder() {
  const userAuth = useRecoilValue(authState);
  return (payload, onLoad, businessId) => {
    payload['business_id'] = businessId || userAuth.businessId
    consoleLog('createOrder', payload)
    RETAILAPI(
      "POST",
      `/partner-api/orders/create`,
      { ...payload }
    )
      .then((res) => {
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}
export function CreateEcomOrder() {
  const userAuth = useRecoilValue(authState);
  return (payload, onLoad, businessId) => {
    payload['business_id'] = businessId || userAuth.businessId
    consoleLog('createOrder', payload)
    RETAILAPI(
      "POST",
      `/partner-api/orders/ecom/create`,
      { ...payload }
    )
      .then((res) => {
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function UpdateOrder() {
  const userAuth = useRecoilValue(authState);
  return (payload, onLoad) => {
    consoleLog('updateOrderr', { ...payload, "business_id": userAuth.businessId })
      RETAILAPI(
        "POST",
        `/partner-api/orders/update`,
        { ...payload, "business_id": userAuth.businessId }
      )
        .then((res) => {
          if (onLoad) onLoad(res);
        })
        .catch((err) => {
          console.log(err);
          if (onLoad) onLoad(err.toJSON())
        });

  };
}

export function useRefundOrder() {
  return (payload, onLoad) => {
    consoleLog('useRefundOrder', payload)
    RETAILAPI(
      "POST",
      `/partner-api/orders/refund`,
      payload
    )
      .then((res) => {
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function useGetOTP() {
  const userAuth = useRecoilValue(authState);
  return (payload, onLoad) => {
    consoleLog('useGetOTP', payload)
    RETAILAPI(
      "POST",
      `/partner-api/orders/get-otp/${userAuth.businessId}`,
      payload
    )
      .then((res) => {
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function useGetBatchOrders() {
  return (tracking_id, onLoad) => {
    consoleLog('useGetBatchOrders', tracking_id)
    RETAILAPI(
      "GET",
      `/partner-api/orders/get-batched-orders/${tracking_id}`,
    )
      .then((res) => {
        console.log("resq", res)
        console.log("onLoad", onLoad)
        if (onLoad) onLoad(res);
        // return res
      })
      .catch((err) => {
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}


export function LoadApartments() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  // const useApartmentState = useRecoilValue(apartmentListState);
  const setAppartmentState = useSetRecoilState(apartmentListState);
  return (onLoad) => {
    setLoaderState(true)
    RETAILAPI(
      "GET",
      `/partner-api/general/apartments/${userAuth.businessId}`
    )
      .then((res) => {
        console.log('res apartment', res.apartments);
        let apartments = _.sortBy(res.apartments, ['name'])
        apartments.unshift({ id: "-1", name: "INDEPENDENT HOUSE / PLOT" });
        apartments.unshift({ id: "", name: "SELECT" })
        apartments.push({ id: "0", name: "APARTMENT NOT IN LIST" })
        setAppartmentState(apartments)
        setLoaderState(false);
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        // setLoaderState(false);
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}


export function FetchCustomerProfile() {
  const userAuth = useRecoilValue(authState);
  const setLoaderState = useSetRecoilState(loaderState);
  const setCustomerProfile = useSetRecoilState(customerProfileState);
  return (phone, onLoad, businessIds, showLoader=false) => {
    if(showLoader){
      setLoaderState(true)
    }
    let data = {
      phone,
      "business_id": userAuth.businessId
    }
    if (businessIds) {
      data.business_id = undefined
      data.business_ids = businessIds
    }
    consoleLog('customerProfile', data)
    RETAILAPI(
      "POST",
      `/partner-api/general/customer-profile`,
      data
    )
      .then((res) => {
        console.log('customer profile', res)
        setCustomerProfile(res)
        if(showLoader){
          setLoaderState(false)
        }
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        console.log(err);
        if(showLoader){
          setLoaderState(false)
        }
        if (onLoad) onLoad(err.toJSON())
      });
  };
}


export function FetchDeliveryOptions() {
  const userAuth = useRecoilValue(authState);
  return (address, phone, onLoad) => {
    const data = {
      phone,
      address,
      "businessId": userAuth.businessId
    }
    consoleLog('deliveryOptions', data)
    GDBXAPI(
      "POST",
      `/goodbox/partnerapi/deliveryoptions`,
      data
    )
      .then((res) => {
        console.log('delivery options', res)
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}


export function useGetBusinessDetails() {
  const history = useHistory();
  const setAuthDta = useSetRecoilState(authState);
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  return (showLoader, onLoad,) => {
    if (!userAuth.businessDetailsLoaded && userAuth.businessId) {
      if (showLoader) setLoaderState(true)
      RETAILAPI(
          "GET",
          `/partner-api/my-business${userAuth.businessId ? `/${userAuth.businessId}` : ''}`
      )
          .then((res) => {
            setLoaderState(false)
            console.log('BusinessDetails', res)
            if (onLoad) onLoad(res);

            removeLocal('signupBusinessDetails');
            removeLocal('signupBusinessLocationDetails');
            removeLocal('signupBusinessWhatsappDetails');
            removeLocal('signupBusinessPictureDetails');

            if (res && res.data && res.data.business && !userAuth.businessDetailsLoaded) {
              const role = res.data.role || "team";
              const businessData = res.data.business;

              setAuthDta((oldState) => {
                setLocal('businessCreated', 'true');
                setLocal('businessId', businessData.supplierLoggedInId);
                setLocal('role', role);

                return {
                  ...oldState,
                  business: res.data.business,
                  businessId: res.data.business && res.data.business.supplierLoggedInId,
                  deliveryByGoodbox: res.data.business && res.data.business.deliveryByGoodbox,
                  role: role,
                  businessDetailsLoaded: true,
                };
              });

              if (businessData.deliveryByGoodbox === 0) {
                history.replace('', null); // remove navigation history
                history.push("/confirm-account")
              } else if (role === "picker"){
                console.log("picker");
              }
            } else {
              if (!(res && res.data && res.data.business)) {
                // removeLocal('businessCreated');
                // removeLocal('business');
                // history.replace('', null); // remove navigation history
                // history.push("/create-account");
              }
            }
          })
          .catch((err) => {
            setLoaderState(false)
            console.log(err);
            if (onLoad) onLoad(err.toJSON())
          });
    }
  };
}

export function useSetSelectedBusiness() {
  // const setAuthDta = useSetRecoilState(authState);

  return async (business) => {
    await setLocal('businessId', business.supplierLoggedInId);
    await setLocal('username', business.supplierLoggedInId);
    window.location.reload();
  }
}


export function useAddTeamMember() {
  const userAuth = useRecoilValue(authState);
  return (payload, onLoad) => {
    payload['business_id'] = userAuth.businessId
    RETAILAPI(
      'POST',
      '/partner-api/my-business/team/update',
      payload
    )
      .then((res) => {
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}


export function useFetchTeamMembers() {
  const userAuth = useRecoilValue(authState);
  const setLoaderState = useSetRecoilState(loaderState);
  const setTeamMembersState = useSetRecoilState(teamMembersState);
  return (onLoad) => {
    setLoaderState(true)
    const data = {
      "businessId": userAuth.businessId
    }
    consoleLog('useFetchTeamMembers', data)
    RETAILAPI(
      "POST",
      `/partner-api/my-business/team`,
      data
    )
      .then((res) => {
        setLoaderState(false)
        console.log('fetch team', res)

        if (res.status === 0 && res.data) {
          setTeamMembersState(res.data.team)
        }
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false)
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}





export function useGetEmbedUrl() {
  const userAuth = useRecoilValue(authState);
  return (tag, onLoad) => {
    let payload = {
      businessId: userAuth.businessId,
      tag
    }
    consoleLog('useGetEmbedUrl', payload)
    RETAILAPI(
      "POST",
      `/partner-api/general/embed-url`,
      payload
    )
      .then((res) => {
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}




export function useGetMultiStores() {
  const setLoaderState = useSetRecoilState(loaderState);
  const setBusinessMultiStoreState = useSetRecoilState(businessMultiStoreState);
  return (onLoad) => {
    setLoaderState(true)
    consoleLog('useGetMultiStores',)
    RETAILAPI(
      "GET",
      `/partner-api/general/multi-stores`
    )
      .then((res) => {
        if(res && res.status === 0){
          setBusinessMultiStoreState(res.data.businesses)
        }
        setLoaderState(false)
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false)
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function useUploadCatalog() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  return (file, onLoad) => {
    setLoaderState(true);
    console.log('catalog upload', { file });
    GDBXFORMAPI(
      "POST",
      `/goodbox/partnerapi/catalog/upload/${userAuth.businessId}`,
      { file }
    )
      .then((res) => {
        setLoaderState(false);
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  }
}

export function useBulkOrdersUpload() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  return (file, onLoad) => {
    setLoaderState(true);
    console.log('bulk orders upload', { file });
    RETAILFORMAPI(
        "POST",
        `/partner-api/orders/create/bulk-upload/${userAuth.businessId}`,
        { file }
    )
        .then((res) => {
          setLoaderState(false);
          if (onLoad) onLoad(res);
        })
        .catch((err) => {
          setLoaderState(false);
          console.log("err:", err);
          if (onLoad) onLoad(err.toJSON())
        });
  }
}

export function useStartAllocation() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  return (onLoad) => {
    setLoaderState(true);
    RETAILAPI(
      "POST",
      `/partner-api/orders/start-allocation/${userAuth.businessId}`,
      {}
    )
      .then((res) => {
        setLoaderState(false);
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log("err:", err);
        if (onLoad) onLoad(err.toJSON())
      });
  }
}

export function useFetchStoreProfileInfo() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  const setStoreProfileState = useSetRecoilState(storeProfileInfostate);
  return(onLoad) => {
    setLoaderState(true);
    GDBXAPI(
      'POST',
      '/goodbox/partnerapi/mybusiness/profile/fetch',
      { businessId: userAuth.businessId }
    )
      .then((res) => {
        if(res && res.status === 0){
          setStoreProfileState({
            days: res.data.days
          })
        }
        setLoaderState(false);
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  }
}

export function useUpdateStoreTimings() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  return(timingsData, onLoad) => {
    setLoaderState(true);
    GDBXAPI(
      'POST',
      '/goodbox/partnerapi/mybusiness/timings/update',
      { timingsData, businessId: userAuth.businessId }
    )
      .then((res) => {
        setLoaderState(false);
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  }
}

export function useSubmitIssue() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  return (issueType, comment, onLoad) => {
    setLoaderState(true);
    console.log('catalog upload', {
      "businessId": userAuth.businessId,
      "comments": comment,
      "issueType": issueType,
      "phone": userAuth.phone
    });
    RETAILAPI(
      "POST",
      `/partner-api/issue`,
      {
        "businessId": userAuth.businessId,
        "comments": comment,
        "issueType": issueType,
        "phone": userAuth.phone
      }
    )
      .then((res) => {
        setLoaderState(false);
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  }
}

export function useRequestForEditOrder() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  return (payload, trackingId, onLoad) => {
    setLoaderState(true);
    RETAILAPI(
      'POST',
      '/partner-api/orders/edit-request',
      { trackingId, editFields : payload, businessId: userAuth.businessId, phone: userAuth.phone }
    )
      .then((res) => {
        setLoaderState(false);
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  }
}



export function useFetchIssues() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  const setIssuesState = useSetRecoilState(issuesState);
  return (status, onLoad) => {
    setLoaderState(true)
    consoleLog('useFetchRosterRiders', { "businessId": userAuth.businessId, status })
    RETAILAPI(
      "POST",
      `/partner-api/issue/records`,
      { "businessId": userAuth.businessId, status }
    )
      .then((res) => {
        setLoaderState(false)
        if (res.status === 0 && res.data) {
          setIssuesState(res.data.issues)
        }
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false)
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function useFetchDistanceInfo() {
  const userAuth = useRecoilValue(authState);
  const setDistanceInfoState = useSetRecoilState(distanceInfoState);
  return (payload, onLoad) =>  {
    payload["businessId"] = userAuth.businessId;
    RETAILAPI(
      'POST',
      '/partner-api/customer/distance',
      payload
    )
      .then((res) => {
        if(res && res.status === 0){
          setDistanceInfoState(res.data.addressInfo)
        }
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  }
}


export function useFetchDashboardNotes() {
  const userAuth = useRecoilValue(authState);
  const setDashboardNotesState = useSetRecoilState(dashboardNotesState);
  return ( onLoad ) => {
    consoleLog('useFetchDashboardNotes', { "businessId": userAuth.businessId })
    RETAILAPI(
      "POST",
      `/partner-api/general/dashboard/notes`,
      { "businessId": userAuth.businessId }
    )
      .then((res) => {
        if (res.status === 0 && res.data) {
          const { severity, show, text } = res.data.notificationContent
          setDashboardNotesState({
            severity: severity,
            msg: text,
            show: show
          })
        }
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}


export function useFetchPickerOrders() {
  const setLoaderState = useSetRecoilState(loaderState);
  const setPickerOrdersState = useSetRecoilState(pickerOrdersState);
  return (query, onLoad) => {
    setLoaderState(true)
    GDBXAPI(
      "GET",
      `/goodbox/pickerapi/order/myorders?q=${query || ''}`
    )
      .then((res) => {
        setLoaderState(false)
        if (res.status === 0 && res.orders) {
          setPickerOrdersState(res.orders)
        }
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false)
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function useCallCustomer() {
  const setLoaderState = useSetRecoilState(loaderState);
  return (trackId, onLoad) => {
    setLoaderState(true)
    GDBXAPI(
        "GET",
        `/goodbox/pickerapi/order/callcustomer/${trackId || ''}`
    )
        .then((res) => {
          setLoaderState(false)
          if (onLoad) onLoad(res);
        })
        .catch((err) => {
          setLoaderState(false)
          console.log(err);
          if (onLoad) onLoad(err.toJSON())
        });
  };
}

export function useFetchPickerOrderInfo() {
  const setLoaderState = useSetRecoilState(loaderState);
  return (trackId, onLoad) => {
    setLoaderState(true)
    GDBXAPI(
      "GET",
      `/goodbox/pickerapi/order/info/${trackId || ''}`
    )
      .then((res) => {
        setLoaderState(false)
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false)
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function useUpdatePickingStatus() {
  const setLoaderState = useSetRecoilState(loaderState);
  return (trackingId, pickingStatus, comments, onLoad) => {
    setLoaderState(true)
    const payload = { trackingId, pickingStatus };
    if (comments && pickingStatus === 'complete') {
      payload['comments'] = comments;
    }
    GDBXAPI(
      "POST",
      `/goodbox/pickerapi/order/pickingstatus`,
      payload
    )
      .then((res) => {
        setLoaderState(false)
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false)
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function useUpdateItemPickingStatus() {
  const setLoaderState = useSetRecoilState(loaderState);
  return (payload, onLoad) => {
    setLoaderState(true)
    GDBXAPI(
      "POST",
      `/goodbox/pickerapi/orderitem/pickingstatus`,
      payload
    )
      .then((res) => {
        setLoaderState(false)
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false)
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function useFetchCatalog() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  return (searchTerm, onLoad) => {
    setLoaderState(true)
    let url = `/api/fetch_catalog?businessId=${userAuth.businessId}&size=200`;
    if (searchTerm && searchTerm.length > 2) {
      url += `&query=${searchTerm}`;
    }
    CATALOGAPI(
        "GET",
        url,
    )
        .then((res) => {
          setLoaderState(false)
          if (onLoad) onLoad(res);
        })
        .catch((err) => {
          setLoaderState(false)
          console.log(err);
          if (onLoad) onLoad(err.toJSON())
        });
  };
}

export function useUpdateCatalogItem() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  return (barcode, price, availability, onLoad) => {
    setLoaderState(true)
    let url = `/api/store/${userAuth.businessId}/update_item/${barcode}`;
    CATALOGAPI(
        "POST",
        url,
        {
          mrp: price,
          discountPrice: price,
          availability: availability
        }
    )
        .then((res) => {
          setLoaderState(false)
          if (onLoad) onLoad(res);
        })
        .catch((err) => {
          setLoaderState(false)
          console.log(err);
          if (onLoad) onLoad(err.toJSON())
        });
  };
}

export function useSearchBarcode() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);;
  return (barcode, onLoad) => {
    setLoaderState(true)
    CATALOGAPI(
      "GET",
      `/api/newmrp?barcodes=${barcode}&businessId=${userAuth.businessId}&size=200`,
    )
      .then((res) => {
        setLoaderState(false)
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false)
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function useAddPickerItem() {
  const setLoaderState = useSetRecoilState(loaderState);
  return (payload, onLoad) => {
    setLoaderState(true)
    GDBXAPI(
      "POST",
      `/goodbox/pickerapi/orderitem/add`,
      payload
    )
      .then((res) => {
        setLoaderState(false)
        if (onLoad) onLoad(res);
      })
      .catch((err) => {
        setLoaderState(false)
        console.log(err);
        if (onLoad) onLoad(err.toJSON())
      });
  };
}

export function useUpdateSelectedChatNumber() {
  const setChatState = useSetRecoilState(chatState);

  return (number, onLoad) => {
    consoleLog('useUpdateSelectedChatNumber', { "number": number})
    setChatState((oldState) => {
      return {
        ...oldState,
        lastSelectedNumber: oldState.selectedNumber,
        selectedNumber: number,
        selectedContact: oldState.contacts.find(c => c.number === number)
      };
    });
  };
}

export function useFetchChatContacts() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  const setChatState = useSetRecoilState(chatState);

  const DATE_ONE_DAY = 60 * 60 * 1000 * 24;
  const now = new Date();

  return (showLoader=true, onLoad) => {
    setLoaderState(showLoader)
    consoleLog('useFetchChatContacts', { "business_id": userAuth.businessId})
    RETAILAPI(
        "POST",
        `/partner-api/whatsapp/contact`,
        JSON.stringify({ "businessId": userAuth.businessId })
    )
        .then((res) => {
          setLoaderState(false);
          setChatState((oldState) => {
            return {
              ...oldState,
              contacts: res.data.contacts.map(contact => {
                const parsedDate = new Date(contact.lastTextAt.replace(/-/g, "/"));
                return {...contact, lastTextAt: parsedDate, moreThanOneDayAgo: (now - parsedDate) > DATE_ONE_DAY}
              }),
              initialLoadComplete: true,
            }
          });
          if (onLoad) onLoad(res);
        })
        .catch((err) => {
          setLoaderState(false);
          console.log(err);
          if (onLoad) onLoad(err.toJSON())
        });
  };
}

export function useFetchChatTemplates() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  const setChatState = useSetRecoilState(chatState);

  return (showLoader=true, onLoad) => {
    setLoaderState(showLoader)
    consoleLog('useFetchChatTemplates', { "business_id": userAuth.businessId})
    RETAILAPI(
        "POST",
        `/partner-api/whatsapp/templates`,
        JSON.stringify({ "businessId": userAuth.businessId })
    )
        .then((res) => {
          setLoaderState(false);
          setChatState((oldState) => {
            return {
              ...oldState,
              templates: res.data.templates.map(template => {
                return {
                  ...template,
                  params: template.text.match(/{{\$\d}}/g) || [],
                  paramsCount: template.text.match(/{{\$\d}}/g)?.length || 0
                }
              }),
            }
          });
          if (onLoad) onLoad(res);
        })
        .catch((err) => {
          setLoaderState(false);
          console.log(err);
          if (onLoad) onLoad(err.toJSON())
        });
  };
}

export function useFetchChatTemplateCampaigns() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  const setChatState = useSetRecoilState(chatState);

  return (showLoader=true, onLoad) => {
    setLoaderState(showLoader)
    consoleLog('useFetchChatTemplateCampaigns', { "business_id": userAuth.businessId})
    RETAILAPI(
        "POST",
        `/partner-api/whatsapp/templates/campaigns`,
        JSON.stringify({ "businessId": userAuth.businessId })
    )
        .then((res) => {
          setLoaderState(false);
          setChatState((oldState) => {
            return {
              ...oldState,
              campaigns: res.data.campaigns.map(campaign => {
                return {
                  ...campaign
                }
              }),
            }
          });
          if (onLoad) onLoad(res);
        })
        .catch((err) => {
          setLoaderState(false);
          console.log(err);
          if (onLoad) onLoad(err.toJSON())
        });
  };
}

export function useFetchChatTemplateCampaignHistory() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  const setChatState = useSetRecoilState(chatState);

  return (showLoader=true, campaignId, onLoad) => {
    setLoaderState(showLoader)
    consoleLog('useFetchChatTemplateCampaignHistory', { "business_id": userAuth.businessId, "campaignId": campaignId })
    RETAILAPI(
        "POST",
        `/partner-api/whatsapp/templates/campaigns/${campaignId}/history`,
        JSON.stringify({ "businessId": userAuth.businessId })
    )
        .then((res) => {
          setLoaderState(false);
          setChatState((oldState) => {
            return {
              ...oldState,
              campaignData: { ...oldState.campaignData, [campaignId]: res.data.history }
              }
            });
          if (onLoad) onLoad(res);
          })
        .catch((err) => {
          setLoaderState(false);
          console.log(err);
          if (onLoad) onLoad(err.toJSON())
        });
  };
}

export function useSendTemplateChatMessage() {
  const userAuth = useRecoilValue(authState);
  const setLoaderState = useSetRecoilState(loaderState);

  return (showLoader=true, message, numbers, type="template", templateName, campaignName, params, url, onLoad) => {
    setLoaderState(showLoader);
    consoleLog('useSendTemplateChatMessage', { templateType: type,
      msgText: message,
      businessId: userAuth.businessId,
      msgMediaUrl: url,
      numbers,
      params,
      templateName,
      campaignName })
    RETAILAPI(
        "POST",
        `/partner-api/whatsapp/templates/send-message`,
        {
          templateType: type,
          msgText: message,
          businessId: userAuth.businessId,
          msgMediaUrl: url,
          numbers,
          params,
          templateName,
          campaignName
        }
    )
        .then((res) => {
          if (onLoad) onLoad(res)
          setLoaderState(false);
        })
        .catch((err) => {
          console.log(err);
          setLoaderState(false);
          if (onLoad) onLoad(err.toJSON())
        });
  };
}

export function useMarkChatRead() {
  const chat = useRecoilValue(chatState);
  const userAuth = useRecoilValue(authState);
  return (selectedNumber, onLoad) => {
    consoleLog('useMarkChatRead', { "businessId": userAuth.businessId, "userPhone": userAuth.phone })
    if (!selectedNumber && !chat.selectedNumber) return;
    RETAILAPI(
        "POST",
        `/partner-api/whatsapp/${selectedNumber || chat.selectedNumber}/mark-read`,
        JSON.stringify({ "businessId": userAuth.businessId, "userPhone": "" })
    )
        .then((res) => {
          if (onLoad) onLoad(res);
        })
        .catch((err) => {
          console.log(err);
          if (onLoad) onLoad(err.toJSON())
        });
  }
}

export function useFetchChatHistory() {
  const setLoaderState = useSetRecoilState(loaderState);
  const userAuth = useRecoilValue(authState);
  const setChatState = useSetRecoilState(chatState);
  const chat = useRecoilValue(chatState);
  return (selectedNumber, onLoad) => {
    consoleLog('useFetchChatHistory', { "business_id": userAuth.businessId})
    if (!selectedNumber && !chat.selectedNumber) return;
    RETAILAPI(
        "POST",
        `/partner-api/whatsapp/${selectedNumber || chat.selectedNumber}/history`,
        JSON.stringify({ "businessId": userAuth.businessId })
    )
        .then((res) => {
          setChatState((oldState) => {
            return {
              ...oldState,
              messages: {
                ...oldState.messages,
                [selectedNumber || chat.selectedNumber]: res.data.conversations,
              },
            }
          });
          if (onLoad) onLoad(res);
        })
        .catch((err) => {
          setLoaderState(false);
          console.log(err);
          if (onLoad) onLoad(err.toJSON())
        });
  }
}

export function useSendChatMessage() {
  const [userData] = useRecoilState(loginState);
  const userAuth = useRecoilValue(authState);
  const chat = useRecoilValue(chatState);
  const fetchChatHistory = useFetchChatHistory();
  const setChatState = useSetRecoilState(chatState);

  return (message, number, type="text", url, onLoad) => {
    consoleLog('useSendChatMessage', { mobile: userData["phone"] })
    if (type === "text" && message.trim().length === 0) return;
    setChatState((oldState) => {
      return {
        ...oldState,
        messages: {
          ...oldState.messages,
          [number]: [...oldState.messages[number], {
            "createdAt": moment().format("YYYY-MM-DD HH:MM:SS"),
            "id": (Math.random() * 1000).toString(),
            "msgFrom": userAuth.businessWhatsappNumber,
            "msgMediaUrl": type === "media" ? url : "",
            "msgStatus": "SENDING",
            "msgText": message,
            "msgTo": number,
            "msgType": type,
            "ts": moment().format("YYYY-MM-DD HH:MM:SS")
          }]
        }
      };
    });
    RETAILAPI(
        "POST",
        `/partner-api/whatsapp/${number}/send-message`,
        { msgType: type, msgText: message, businessId: userAuth.businessId, msgMediaUrl: url }
    )
        .then((res) => {
          if (onLoad) onLoad(res)
          fetchChatHistory(chat.selectedNumber);
        })
        .catch((err) => {
          console.log(err);
        });
  };
}

class FCMTokenListener extends EventTarget {
  sentToken() {
    console.log('sent token firing');
    this.dispatchEvent(new Event('sentToken'));
  }
}

window._fcmTokenListener = new FCMTokenListener();

window._sendFcmToken = (token) => {
  RETAILAPI(
      "POST",
      `/partner-api/customer/fcm-token`,
      token
  )
      .then((res) => {
        if (res) {
          window._fcmTokenListener && window._fcmTokenListener.sentToken();
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function useSendFcmTokenAddress() {

  return (token) => {
    consoleLog('useSendFcmTokenAddress', token)
    window._sendFcmToken(token);
  };
}
