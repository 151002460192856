import * as Sentry from "@sentry/react";
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/css/tailwind.output.css'
import App from './App'
import { SidebarProvider } from './context/SidebarContext'
import { MultiStoreSwitcherProvider } from "./context/MultiStoreSwitcherContext";
import ThemedSuspense from './components/ThemedSuspense'
import { Windmill } from '@windmill/react-ui'
import * as serviceWorker from './serviceWorker'
import myTheme from './myTheme.js'
import { checkAutoLogin } from './common/services/CommonService'
import { initWebviewBridge } from "./WebViewBridge";

initWebviewBridge();

if (window.ReactNativeWebView) {
    console.log('running inside webview');
}

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }

Sentry.init({
  dsn: "https://9fad49b90ab74ff29a5ff140140fda8b@o115711.ingest.sentry.io/5677147",
  autoSessionTracking: true,
  sendDefaultPii: true,
});


checkAutoLogin()

localStorage.setItem("theme", "light")

ReactDOM.render(
  <SidebarProvider>
      <MultiStoreSwitcherProvider>
        <Suspense fallback={<ThemedSuspense />}>
          <Windmill usePreferences theme={myTheme}>
            <App />
          </Windmill>
        </Suspense>
      </MultiStoreSwitcherProvider>
  </SidebarProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
