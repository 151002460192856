export const config = {
  catalogApiHostname: "https://catalogapi.goodbox.in",
  gdbxApiHostname: "https://api.goodbox.in",
  retailApiHostname: "https://retail-api.loadshare.net",
  mapKey: "AIzaSyDyYw0EIS6NMx82jZgbCkMC8WLxGPcSXQ0",
  googleGeoCodingKey: "AIzaSyDyYw0EIS6NMx82jZgbCkMC8WLxGPcSXQ0",
  version: "2021062823",
  versionString: "2.0.0",
  package: "partners.goodbox.in",
  lightGalleryLicenseKey: "3C02FA99-56D44AD1-B8E6B8C2-A5AF3B66"
};

// 🚨 DEV
// export const config = {
//   catalogApiHostname: "http://localhost:4000",
//   gdbxApiHostname: "http://localhost:8000",
//   mapKey: "AIzaSyCbIJHpUgX1bag6c2n-0Gd5p8Colhq4GcA",
//   googleGeoCodingKey: "AIzaSyC80NFmCmTfiil0IS3pS1xqs5tYz5ft1Ps",
//   version: "2021062823",
//   versionString: "1.3.7",
//   package: "partners.goodbox.in"
// };
