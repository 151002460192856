import firebase from 'firebase/app'
import "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDXT-4KeXMRJzns7MgZyduhXKKGzir7U8Q",
    authDomain: "goodbox-partner.firebaseapp.com",
    projectId: "goodbox-partner",
    storageBucket: "goodbox-partner.appspot.com",
    messagingSenderId: "622748467054",
    appId: "1:622748467054:web:cb325eb7f6733e67ba8b7f",
    measurementId: "G-3TMNSJQ6S4"
};

let messaging;

try {
    firebase.initializeApp(firebaseConfig);
    messaging = firebase.messaging(); //firebase.messaging.isSupported() ? initializedFirebaseApp.messaging() : null;

    messaging.onMessage((payload) => {
        console.log('Foreground Message received. ', payload);
    });
} catch (err) {
    console.error(err);
}

export { messaging };
