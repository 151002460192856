import React, { lazy } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import {RecoilRoot} from "recoil";
import AuthRoute from './components/AuthRoute';
import { RestoreAuthFromStorage } from './common/recoil/apis';
import {storage} from "./common/services/storage";

import "./styles.scss";

const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const CreateAccount = lazy(() => import('./pages/CreateAccount/index'))
const ConfirmAccount = lazy(() => import('./pages/ConfirmAccount/index'))


function App() {
  const [authRestored, setAuthRestored] = React.useState(false);

  React.useEffect(() => {
    async function hydrateState() {
      /* eslint-disable no-unused-vars */
      const pushToken = await storage.hydrateItem('pushToken');
      const clientId = await storage.hydrateItem('clientid');
      const authToken = await storage.hydrateItem('authtoken');
      const username = await storage.hydrateItem('username');
      const phone = await storage.hydrateItem('phone');
      const deliveryByGoodbox = await storage.hydrateItem('deliveryByGoodbox', 'int');
      const business = await storage.hydrateItem('business', 'json');
      const businessCreated = await storage.hydrateItem('businessCreated');
      const role = await storage.hydrateItem('role');
      const businessId = await storage.hydrateItem('businessId');
      /* eslint-enable no-unused-vars */
      console.log("values: ", pushToken, clientId, authToken, username);
      setAuthRestored(true);
    }
    hydrateState();
  }, []);

  if (!authRestored) {
    console.log('auth not restored');
    return null;
  }

  console.log('auth restored');

  return (
    <RecoilRoot>
      <Router>
        <RestoreAuthFromStorage />
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/webLogin" component={Login} />
          <AuthRoute path="/create-account" component={CreateAccount} />
          <AuthRoute path="/confirm-account" component={ConfirmAccount} />
          {/* Place new routes over this */}
          <AuthRoute path="/dashboard" component={Layout} />
          {/* If you have an index page, you can remothis Redirect */}
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </RecoilRoot>
  )
}


export default App
