import React, { useState, useMemo } from 'react'

// create context
export const MultiStoreSwitcherContext = React.createContext()

export const MultiStoreSwitcherProvider = ({ children }) => {
    const [isMultiStoreSwitcherOpen, setIsMultiStoreSwitcherOpen] = useState(false)

    function closeMultiStoreSwitcher() {
        setIsMultiStoreSwitcherOpen(false);
    }

    function openMultiStoreSwitcher() {
        setIsMultiStoreSwitcherOpen(true);
    }

    const value = useMemo(
        () => ({
            isMultiStoreSwitcherOpen,
            closeMultiStoreSwitcher,
            openMultiStoreSwitcher
        }),
        // eslint-disable-next-line
        [isMultiStoreSwitcherOpen,]
    )

    return <MultiStoreSwitcherContext.Provider value={value}>{children}</MultiStoreSwitcherContext.Provider>
}
