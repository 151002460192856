import { atom } from "recoil";
import { getDateString } from "../services/CommonService";

export const loaderState = atom({
  key: "loaderState",
  default: false,
});

export const pageState = atom({
  key: "pageState",
  default: '',
});


export const selectedDateState = atom({
  key: "selectedDateState",
  default: getDateString(),
});
export const selectedRosterDateState = atom({
  key: "selectedRosterDateState",
  default: getDateString(),
});



export const toastState = atom({
  key: "toastState",
  default: {
    show: false, msg: null
  },
});

export const loginState = atom({
  key: "loginState",
  default: {},
});

export const authState = atom({
  key: "authState",
  default: {},
});

export const loginLoadingState = atom({
  key: "loginLoadingState",
  default: false,
});

export const googleAddressState = atom({
  key: "googleAddressState",
  default: {},
});

export const ordersState = atom({
  key: "ordersState",
  default: {
    allOrders: [],
    newOrders: [],
    billedOrders: [],
    pickedOrders: [],
    deliveredOrders: [],
    newCount: 0,
    billedCount: 0,
    pickedCount: 0,
    deliveredCount: 0,
  },
});


export const ridersState = atom({
  key: "ridersState",
  default: [],
});

export const areaNameState = atom({
  key: "areaNameState",
  default: [],
});

export const rosterRidersState = atom({
  key: "rosterRidersState",
  default: [],
});

export const ridersDashboardState = atom({
  key: "ridersDashboardState",
  default: '',
});

export const teamMembersState = atom({
  key: "teamMembersState",
  default: [],
});


export const apartmentListState = atom({
  key: "apartmentListState",
  default: [],
});

export const customerProfileState = atom({
  key: "customerProfileState",
  default: {},
})

export const issuesState = atom({
  key: "issuesState",
  default: [],
});


export const pickerOrdersState = atom({
  key: "pickerOrdersState",
  default: [],
});


export const dashboardNotesState = atom({
  key: "dashboardNotesState",
  default: {
    show: false,
    msg: '',
    severity: 'info'
  },
});

export const storeProfileInfostate = atom({
  key: "storeProfileInfostate",
  default: {
    days: []
  }
})

export const chatState = atom({
  key: "chatState",
  default: {
    contacts: [],
    messages: [],
    selectedNumber: '',
    templates: [],
    campaigns: [],
    campaignData: {}
  },
});


export const distanceInfoState = atom({
  key: "distanceInfoState",
  default: {}
})

export const businessMultiStoreState = atom({
  key: "businessMultiStoreState",
  default: []
})
